import { combineReducers } from "@reduxjs/toolkit";
import containerReducer from "../modules/Container/slice";
import chatbotReducer from "../modules/Chatbot/slice";
import commonReducer from "./commonReducer";
import loginInfoReducer from "./login";

const rootReducer = combineReducers({
  container: containerReducer,
  chatbot: chatbotReducer,
  common: commonReducer,
  login: loginInfoReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
