// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/Bower-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/McKinseySans-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/McKinseySans-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/fonts/McKinseySans-Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Bower";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "McKinsey Sans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "McKinsey Sans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "McKinsey Sans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
  font-weight: 700;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/sass/fonts.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,4CAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AAEE;EACE,4BAAA;EACA,2DAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,4BAAA;EACA,2DAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAIE;EACE,4BAAA;EACA,2DAAA;EACA,gBAAA;EACA,kBAAA;AAFJ","sourcesContent":["@font-face {\n    font-family: \"Bower\";\n    src: url(\"../assets/fonts/Bower-Bold.woff\");\n    font-style: normal;\n    font-weight: normal;\n  }\n  \n  @font-face {\n    font-family: \"McKinsey Sans\";\n    src: url(\"../assets/fonts/McKinseySans-Light.woff\") format(\"woff\");\n    font-weight: 300;\n    font-style: normal;\n  }\n  \n  @font-face {\n    font-family: \"McKinsey Sans\";\n    src: url(\"../assets/fonts/McKinseySans-Regular.woff\") format(\"woff\");\n    font-weight: normal;\n    font-style: normal;\n  }\n  \n  @font-face {\n    font-family: \"McKinsey Sans\";\n    src: url(\"../assets/fonts/McKinseySans-Medium.woff\") format(\"woff\");\n    font-weight: 700;\n    font-style: normal;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
