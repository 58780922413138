// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauthenticatedPage {
  width: 980px;
  margin: 0 auto;
  box-shadow: rgb(221, 221, 221) 0px 0px 10px 0px;
  background-color: rgb(250, 250, 250);
  display: flex;
  flex-direction: column;
  height: 85%;
}
.unauthenticatedPage .unauth-header .header {
  padding: 0.75rem;
  max-height: 64px;
  background-color: rgb(243, 243, 243);
}
.unauthenticatedPage .unauth-header img {
  display: inline-block;
  padding-top: 0.25rem;
  max-width: 104px;
  min-height: auto;
  margin-top: 0px;
  width: auto;
}
.unauthenticatedPage .heading-1 {
  color: #000;
  font-size: 28px;
}
.unauthenticatedPage .action_image {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.unauthenticatedPage .unauth-text {
  color: rgb(153, 153, 153);
  font-size: 14px;
}
.unauthenticatedPage .return_btn {
  background: #000;
  color: #fff;
  margin: 0 auto;
  padding: 10px 0;
  width: 50%;
}
.unauthenticatedPage .return_btn:focus, .unauthenticatedPage .return_btn:active, .unauthenticatedPage .return_btn:hover {
  background: #000;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/unauthorized.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,cAAA;EACA,+CAAA;EACA,oCAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;AACJ;AACQ;EACI,gBAAA;EACA,gBAAA;EACA,oCAAA;AACZ;AACQ;EACI,qBAAA;EACA,oBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;AACZ;AAEI;EACI,WAAA;EACA,eAAA;AAAR;AAEI;EACI,aAAA;EACA,uBAAA;EACA,cAAA;AAAR;AAEI;EACI,yBAAA;EACA,eAAA;AAAR;AAEI;EACI,gBAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,UAAA;AAAR;AACQ;EACI,gBAAA;EACA,WAAA;AACZ","sourcesContent":[".unauthenticatedPage {\n    width: 980px;\n    margin: 0 auto;\n    box-shadow: rgb(221, 221, 221) 0px 0px 10px 0px;\n    background-color: rgb(250, 250, 250);\n    display: flex;\n    flex-direction: column;\n    height: 85%;\n    .unauth-header {\n        .header{\n            padding: 0.75rem;\n            max-height: 64px;\n            background-color: rgb(243, 243, 243);\n        }\n        img {\n            display: inline-block;\n            padding-top: 0.25rem;\n            max-width: 104px;\n            min-height: auto;\n            margin-top: 0px;\n            width: auto;\n        }\n    }\n    .heading-1 {\n        color: #000;\n        font-size: 28px;\n    }\n    .action_image {\n        display: flex;\n        justify-content: center;\n        margin: 50px 0;\n    }\n    .unauth-text {\n        color: rgb(153, 153, 153);\n        font-size: 14px;\n    }\n    .return_btn {\n        background: #000;\n        color: #fff;\n        margin: 0 auto;\n        padding: 10px 0;\n        width: 50%;\n        &:focus, &:active, &:hover {\n            background: #000;\n            color: #fff;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
