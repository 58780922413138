import React from "react";
import { Step } from "react-joyride";

export const steps: Step[] = [
  {
    title: <div className='tour-title'>GenAI Coach</div>,
    content: (
      <div className='tour-content'>
        Choose a given prompt or enter your own question in the chat and the coach will respond accordingly. The AI coach can assist you in
        explaining fields and definitions. As you add content into the worksheet, the coach will evaluate your responses and may suggest
        improvements.
      </div>
    ),
    floaterProps: {
      hideArrow: true
    },
    locale: {
      skip: <div className='tour-skip'>Skip tutorial</div>,
      next: <div className='tour-next'>Next step</div>
    },
    placement: "left-start",
    target: ".chatbot-container",
    disableBeacon: true
  },
  {
    title: <div className='tour-title'>Worksheet fields</div>,
    content: (
      <div className='tour-content'>
        This is an interactive problem statement worksheet that can have content entered directly and evaluated by the Coach as you go. You
        will start with completing a problem statement question in order to move forward with the rest of the worksheet. This is your main
        source of entry.
      </div>
    ),
    floaterProps: {
      hideArrow: true
    },
    locale: {
      skip: <div className='tour-skip'>Skip tutorial</div>,
      next: <div className='tour-next'>Next step</div>
    },
    target: ".content",
    placement: "right-start",
    disableBeacon: true
  },
  {
    title: <div className='tour-title'>Activity navigation</div>,
    content: (
      <div className='tour-content'>
        The navigation allows you to view a realistic example of the problem statement worksheet, reset your worksheet to a blank slate, and
        download a completed worksheet file once all fields are filled with content.
      </div>
    ),
    floaterProps: {
      hideArrow: true
    },
    locale: { last: <div className='tour-last'>Get started</div> },
    placement: "left-start",
    target: ".action-button-container",
    disableBeacon: true
  }
];
