import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./sass/fonts.scss";
import "./sass/variables.scss";
import "./sass/base.scss";
import App from "./App";
import { Mid } from "@mid/sdk";
import reportWebVitals from "./reportWebVitals";
import { CONFIG } from "./utils/env";
import appStore from "./store";

const mid = new Mid({
  redirectUrl: `${CONFIG.redirectBaseUrl}/auth/callback`,
  logoutRedirectUrl: `${CONFIG.redirectBaseUrl}/logout`,
  loginInfo: {
    appId: CONFIG.clientId,
    oidcConfigUrl: CONFIG.oidcConfigUrl,
    authDriver: "mid",
    flow: "auth_code"
  },
  async landingFn() {
    window.location.hash = "";
  }
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={appStore}>
        <App mid={mid} />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
