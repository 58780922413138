import React, { useEffect, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import {
  Grid,
  Button,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  SIZE_MEDIUM,
  Icon,
  TYPE_OUTLINE,
  TYPE_COLORED,
  ThemeContext,
  CircularProgress,
  SIZE_LARGE,
  ToastFactory,
  pushToast,
  removeToast,
  Alert,
  WARNING_ALERT_GLOBAL,
  getAlertUniqueId,
  Tooltip
} from "@mds/mds-reactjs-library";
import Outline12MoveLayerDown from "@mds/mds-icons/icons/svg/outline-16-move-layer-down.svg";
import InfoCircle from "../../assets/info-circle.svg";
import CardLayout from "../../components/Card";
import Chatbot from "../Chatbot";
import { fetchProblemData, triggerReset, triggerExportProblemData, triggerGetQuickLinks, resetExportLink, resetQuickLinks } from "./slice";
import { setChatbotHistory } from "../Chatbot/slice";
import { setEdit } from "../../store/commonReducer";
import { problemStatementSequence, SMART_PROBLEM } from "./constants";
import { fetchChatbot } from "../Chatbot/slice";
import expandPull from "../../assets/expandPull.svg";
import { LoadingIcon } from "../../common/svg";

import * as constants from "../Chatbot/constants";
import LegalModal from "../../components/LegalModal";
import ExploreModal from "../../components/ExploreModal";
import ExampleModal from "../../components/ExampleModal";
import { ProblemStatement } from "./types";
import { ResetIcon } from "../../common/svg";
import "./styles.scss";
import { steps } from "./steps";

const Container = () => {
  const dispatch = useDispatch();
  const theme = React.useContext(ThemeContext);
  const resetIcon = <ResetIcon />;

  const [position, setPosition] = useState(7);
  const [ToastId, setToastId] = React.useState<number>(1);
  const [resetBar, setResetBar] = useState(false);
  const [legalModal, setLegalModal] = useState(false);
  const [exploreModal, setExploreModal] = useState(false);
  const [exampleModal, setExampleModal] = useState(false);
  const [run, setRun] = useState(false);
  const [mainKey, setMainKey] = useState(0);
  const [activeCard, setActiveCard] = useState<string>("");
  const [exportDisabled, setExportDisabled] = useState<boolean>(true);

  const problemStatementData = useSelector((state: RootState) => state.container.problemStatementData);
  const isLoading = useSelector((state: RootState) => state.container.loading);
  const isExportLoading = useSelector((state: RootState) => state.container.exportLoading);
  const isChatBotLoading = useSelector((state: RootState) => state.chatbot.chatbotLoading);
  const error = useSelector((state: RootState) => state.container.error);
  const exportlink = useSelector((state: RootState) => state.container.exportlink);
  const editMode = useSelector((state: RootState) => state.common.editMode);
  const { lp_id, user_id: uid, access_token } = useSelector((state: RootState) => state.login);
  const downloadIcon = isExportLoading ? <LoadingIcon /> : <Icon src={Outline12MoveLayerDown} type={TYPE_OUTLINE} name='download' />;

  function isProblemStatementKey(key: string): key is keyof ProblemStatement {
    return ["constraints", "context", "insights", "scope", "smart_problem", "stakeholders", "success"].includes(key);
  }

  const getQuickLinks = (section: string) => {
    dispatch(triggerGetQuickLinks({ section, access_token }));
  };

  useEffect(() => {
    if (uid) {
      dispatch(fetchProblemData({ userId: uid, lp_id: lp_id || `${constants.LEARNING_PLAN_ID}`, access_token }));
    }
  }, [uid]);

  useEffect(() => {
    if (localStorage.getItem("legalModal") === null) {
      setLegalModal(true);
    }
  }, []);

  useEffect(() => {
    if (exportlink) {
      window.open(exportlink, "newwindow");
      dispatch(resetExportLink());
    }
  }, [exportlink]);

  useEffect(() => {
    let checkAllProblemStatment = true;
    const problemStatementDraft: ProblemStatement = problemStatementData;
    Object.keys(problemStatementDraft).map((key) => {
      if (isProblemStatementKey(key) && problemStatementDraft[key].text === "") {
        checkAllProblemStatment = false;
      }
    });
    if (checkAllProblemStatment) {
      setExportDisabled(false);
    } else {
      setExportDisabled(true);
    }
  }, [problemStatementData]);

  useEffect(() => {
    if (error) {
      pushToast({
        id: ToastId.toString(),
        type: "danger",
        children: error,
        autoRemoveDuration: 3000,
        onCloseClick: () => removeToast("1")
      });
      setToastId(ToastId + 1);
    }
  }, [error]);

  const handleResetClick = () => {
    setResetBar(true);
    if (editMode) {
      dispatch(setEdit(false));
    }
  };

  const handleExport = () => {
    if (!exportDisabled) {
      dispatch(triggerExportProblemData({ userId: uid || "", lp_id: lp_id || `${constants.LEARNING_PLAN_ID}`, access_token }));
    }
  };

  const handleReset = () => {
    dispatch(triggerReset({ userId: uid || "", lp_id: lp_id || `${constants.LEARNING_PLAN_ID}`, access_token }));
    setResetBar(false);
    setActiveCard("");
  };

  const handleSubmit = ({ key, content }: { key: string; content: string }) => {
    const chatBotMessage = {
      user_type: constants.USER,
      message: content,
      mode: "draft",
      current_section: key
    };
    dispatch(setChatbotHistory(chatBotMessage));
    dispatch(
      fetchChatbot({
        userId: uid,
        lp_id: lp_id || `${constants.LEARNING_PLAN_ID}`,
        user_selection: content,
        evaluate: true,
        access_token,
        current_section: key,
        mode: "draft"
      })
    );
  };

  const handleMouseDown = (event: React.MouseEvent): void => {
    const initialX = event.clientX;
    const containerWidth = (event.currentTarget as HTMLDivElement | SVGSVGElement).parentElement?.clientWidth || 0;
    const initialPosition = position;
    const handleMouseMove = (event: MouseEvent): void => {
      const deltaX = event.clientX - initialX;
      const newPosition = Math.min(Math.max(5, initialPosition + (deltaX / containerWidth) * 12), 8);
      setPosition(newPosition);
    };
    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleAckknowledge = () => {
    localStorage.setItem("legalModal", "true");
    setLegalModal(false);
    if (localStorage.getItem("exploreModal") === null) {
      setExploreModal(true);
      localStorage.setItem("exploreModal", "true");
    }
  };

  const handleModalClose = () => {
    setExploreModal(false);
  };

  const handleExample = () => {
    setExampleModal(false);
  };
  const handleExploreTutorial = () => {
    setExploreModal(false);
    setRun(true);
  };
  const resetQuicklinksData = () => {
    dispatch(resetQuickLinks());
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action == ACTIONS.CLOSE) {
      setRun(false);
      setMainKey(mainKey + 1);
    }
  };

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        key={mainKey}
        continuous
        run={run}
        hideBackButton
        scrollToFirstStep
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            width: 400,
            backgroundColor: "#CCF2FF",
            arrowColor: "#CCF2FF",
            primaryColor: "#CCF2FF"
          }
        }}
      />
      {resetBar && (
        <Alert
          onCloseClick={() => {
            setResetBar(false);
          }}
          id={getAlertUniqueId()}
          type={WARNING_ALERT_GLOBAL}
          primaryButton={{
            label: "Reset",
            action: () => {
              handleReset();
            }
          }}
          secondaryButton={{
            label: "Cancel",
            action: () => {
              setResetBar(false);
            }
          }}
        >
          This will delete your worksheet and dialogue
        </Alert>
      )}
      <Grid container className={`container mainWrapper ${resetBar ? "showAlert" : ""}`}>
        <ToastFactory />
        <Grid container className='app-heading'>
          <Grid item span md={{ span: 6 }}>
            <div className='page-heading'>
              Problem Statement Worksheet
              <Tooltip dark={false} content='Learn how to use the AI Coach' placement='bottom'>
                <span
                  className='infoIcon'
                  onClick={() => {
                    setExploreModal(true);
                  }}
                >
                  <Icon fill='red' src={InfoCircle} type={TYPE_COLORED} name='info' />
                </span>
              </Tooltip>
            </div>
          </Grid>
          <Grid item span className='text-right action-button' md={{ span: 6 }}>
            <div className='action-button-container'>
              <Button
                size={SIZE_MEDIUM}
                appearance={SECONDARY_BUTTON}
                onClick={() => {
                  setExampleModal(true);
                }}
              >
                View Example
              </Button>
              <Button startIcon={resetIcon} size={SIZE_MEDIUM} appearance={SECONDARY_BUTTON} onClick={handleResetClick}>
                Reset Activity
              </Button>
              <Button
                className='download-btn'
                size={SIZE_MEDIUM}
                disabled={exportDisabled}
                appearance={PRIMARY_BUTTON}
                startIcon={downloadIcon}
                onClick={handleExport}
              >
                Download
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid item span={12} md={{ span: position }} className='content'>
          {isLoading ? (
            <div className='loader'>
              <CircularProgress indeterminate size={SIZE_LARGE} />
            </div>
          ) : (
            <div className='problem-statement'>
              <div className='problem-question'>
                <CardLayout
                  heading='Problem question'
                  data={problemStatementData.smart_problem}
                  sectionKey={SMART_PROBLEM}
                  handleSubmit={handleSubmit}
                  isChatBotLoading={isChatBotLoading}
                  activeCard={activeCard}
                  setActiveCard={setActiveCard}
                  getQuickLinks={getQuickLinks}
                  resetQuicklinksData={resetQuicklinksData}
                ></CardLayout>
              </div>
              <Grid className='small-card-question' container spacing={theme.spacing.small}>
                {problemStatementSequence.map((key) => (
                  <Grid item span={12} md={{ span: 6 }} className='small-card' key={key}>
                    <CardLayout
                      heading={key}
                      data={problemStatementData[key]}
                      sectionKey={key}
                      handleSubmit={handleSubmit}
                      isChatBotLoading={isChatBotLoading}
                      activeCard={activeCard}
                      setActiveCard={setActiveCard}
                      getQuickLinks={getQuickLinks}
                      resetQuicklinksData={resetQuicklinksData}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </Grid>
        <Grid item span={12} md={{ span: 12 - position }} className='chatbot'>
          <Icon src={expandPull} name='expandable' className='expandable' onMouseDown={(e) => handleMouseDown(e)} />
          <Chatbot />
        </Grid>
      </Grid>
      {legalModal && <LegalModal handleAckknowledge={handleAckknowledge} />}
      {exploreModal && <ExploreModal handleExploreTutorial={handleExploreTutorial} handleModalClose={handleModalClose} />}
      {exampleModal && <ExampleModal handleExample={handleExample} />}
    </>
  );
};

export default Container;
