import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchProblemData,
  fetchProblemDataSuccess,
  fetchProblemDataFailure,
  updateProblemData,
  updateProblemDataSuccess,
  updateProblemDataFailure,
  triggerReset,
  triggerResetFailure,
  triggerResetSuccess,
  triggerExportProblemData,
  triggerExportProblemSuccess,
  triggerExportProblemFailure,
  triggerGetQuickLinks,
  triggerGetQuickLinksSuccess,
  triggerGetQuickLinksFailure
} from "./slice";
import callApi from "../../utils/api";
import { POST, PUT, GET } from "../../utils/constants";
import type { savePayload, resetPayload, problemDataPayload, quickLinkPayload } from "./types";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { fetchChatbotHistory, resetChatHistory } from "../Chatbot/slice";

function* fetchProblemStatementData(action: PayloadAction<problemDataPayload>): Generator<any, void, any> {
  try {
    const data = yield call(
      callApi,
      `api/academy-ai/get_problem_data?&learning_plan_id=${action.payload.lp_id}&docebo_user_id=${action.payload.userId}`,
      GET,
      action.payload.access_token
    ); // Call the API function
    yield put(fetchProblemDataSuccess(data));
  } catch (error: any) {
    yield put(fetchProblemDataFailure(error.message)); // Dispatch failure action with the error message
  }
}

function* resetDataSaga(action: PayloadAction<resetPayload>): Generator<any, void, any> {
  const payload = { docebo_user_id: action.payload.userId, learning_plan_id: action.payload.lp_id };
  try {
    const data = yield call(callApi, `api/academy-ai/reset`, POST, action.payload.access_token, payload);
    yield put(triggerResetSuccess(data));
    yield put(
      resetChatHistory({
        userId: action.payload.userId,
        lp_id: action.payload.lp_id,
        access_token: action.payload.access_token,
        current_section: "smart_problem",
        mode: "chat"
      })
    );
    yield put(
      fetchChatbotHistory({
        userId: action.payload.userId,
        offset: 0,
        lp_id: action.payload.lp_id,
        access_token: action.payload.access_token
      })
    );
  } catch (error: any) {
    yield put(triggerResetFailure(error.message));
  }
}

function* updateProblemDataSaga(action: PayloadAction<savePayload>): Generator<any, void, any> {
  const payload = {
    docebo_user_id: action.payload.userId,
    learning_plan_id: action.payload.lp_id,
    attribute: action.payload.key,
    value: action.payload.content
  };
  try {
    yield call(callApi, `api/academy-ai/save_problem_statement`, PUT, action.payload.access_token, payload);
    yield put(updateProblemDataSuccess());
  } catch (error: any) {
    yield put(updateProblemDataFailure());
  }
}

function* exportProblemData(action: PayloadAction<savePayload>): Generator<any, void, any> {
  try {
    const data = yield call(
      callApi,
      `api/academy-ai/export_psw?&learning_plan_id=${action.payload.lp_id}&docebo_user_id=${action.payload.userId}`,
      GET,
      action.payload.access_token
    );
    const payload = { link: data };
    yield put(triggerExportProblemSuccess(payload));
  } catch (error: any) {
    yield put(triggerExportProblemFailure());
  }
}

function* fetchQuickLink(action: PayloadAction<quickLinkPayload>): Generator<any, void, any> {
  try {
    const payload = {
      section: action.payload.section
    };
    const data = yield call(callApi, `api/academy-ai/quick-links`, POST, action.payload.access_token, payload);
    yield put(triggerGetQuickLinksSuccess({ ...data, user_section: action.payload.section }));
  } catch (error: any) {
    yield put(triggerGetQuickLinksFailure());
  }
}

const containerSaga = [
  takeLatest(fetchProblemData.type, fetchProblemStatementData),
  takeLatest(updateProblemData.type, updateProblemDataSaga),
  takeLatest(triggerReset.type, resetDataSaga),
  takeLatest(triggerExportProblemData.type, exportProblemData),
  takeLatest(triggerGetQuickLinks.type, fetchQuickLink)
];
export default containerSaga;
