import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Update to 'react-router-dom' for TypeScript
import { useDispatch } from "react-redux";
import { Box } from "@mds/mds-reactjs-library";
import { parseJwt } from "../../utils/function";
import { CONFIG, PATHS } from "../../utils/env";
import AuthStore from "../../common/AuthStore";
import Loader from "../Loader";
import tokenApi from "../../utils/tokenApi";
import Unauthenticated from "../Unauthenticated";
import { updateState } from "../../store/login";
import { MidAuthnContext } from "@mid/sdk";

interface MidProps {
  accessToken: () => string | null;
  isAuthed: () => Promise<boolean>;
  login: () => Promise<MidAuthnContext>;
}

interface CallbackComponentProps {
  mid: MidProps;
  children?: React.ReactNode;
}

const CallbackComponent: React.FC<CallbackComponentProps> = ({ mid }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [status, setStatus] = useState<boolean>(false);

  const checkvalidateuser = async () => {
    try {
      const midToken = mid.accessToken();
      if (midToken) {
        const response = await tokenApi(CONFIG.TOKEN_VALIDATION, "GET", midToken);
        if (response) {
          setStatus(false);
          const infoFromToken = parseJwt(midToken);
          const { auth_time = 0, given_name = "", family_name = "", email = "" } = infoFromToken;
          dispatch(
            updateState({ access_token: midToken, source: "", user_id: email, lp_id: "", first_name: given_name, last_name: family_name })
          );
          AuthStore.midToken = midToken;
          AuthStore.auth_time = auth_time;
          AuthStore.accessToken = midToken;
          redirectURL();
        }
      }
    } catch (error) {
      console.error("Exception occurred...!");
      console.error(error);
      setStatus(true);
    }
  };

  useEffect(() => {
    const midToken = mid.accessToken();
    if (midToken) {
      checkvalidateuser();
    }
  }, []);

  const redirectURL = () => {
    const redirectUrl = AuthStore.redirectionURL;
    history(redirectUrl || PATHS.ACADEMY);
    AuthStore.redirectionURL = "";
  };

  if (status) {
    return <Unauthenticated />;
  }

  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh"
      }}
      data-testid='call'
    >
      <Box
        style={{
          position: "absolute",
          left: "45vw",
          top: "45vh"
        }}
      >
        <Loader position='fixed' showBackground background='transparent' />
      </Box>
    </Box>
  );
};

export default CallbackComponent;
