import React, { useState, useEffect } from "react";
import coachAvatar from "../assets/coachAvatar.svg";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { Icon, TYPE_OUTLINE } from "@mds/mds-reactjs-library";
import type { Message } from "../modules/Chatbot/types";
import Outline16ThumbDown from "@mds/mds-icons/icons/svg/outline-16-thumb-down.svg";
import FormatText from "./FormatText";
import Feedback from "./Feedback";

interface Props {
  content: Message;
  onDislikeClick: ({ feedback, message_id }: { message_id?: number; feedback?: number; feedback_comment?: string }) => void;
}

const CoachChatBox = ({ content, onDislikeClick }: Props) => {
  const { message = "", evaluation_feedback = "", message_id, feedback, mode } = content;
  const [dislike, setDislike] = useState<boolean>(false);
  const [showFeedbackComment, setShowFeedbackComment] = useState<boolean>(false);
  const first_name = useSelector((state: RootState) => state.login.first_name);

  useEffect(() => {
    if (feedback) {
      setDislike(true);
    }
  }, [feedback]);

  const handleDislikeClick = () => {
    onDislikeClick({ feedback: Number(!dislike), message_id });
    setShowFeedbackComment(!dislike);
    setDislike(!dislike);
  };

  const handleFeedbackSend = (text: string) => {
    onDislikeClick({ feedback_comment: text, message_id });
    setShowFeedbackComment(false);
  };

  return (
    <>
      <div className='coach-chatbox'>
        <div className='name'>
          <img src={coachAvatar} className='coach-avatar' /> Coach
        </div>
        <div className={`text ${mode === "draft" ? "draftText" : ""}`}>
          {FormatText(message || evaluation_feedback, first_name)}

          <div className='feedback_icons'>
            <Icon
              src={Outline16ThumbDown}
              type={TYPE_OUTLINE}
              name='dislike'
              className={dislike || feedback ? "active" : ""}
              onClick={handleDislikeClick}
            />
          </div>
        </div>
      </div>
      {showFeedbackComment && <Feedback onSendClick={handleFeedbackSend} onCloseClick={() => setShowFeedbackComment(false)} />}
    </>
  );
};

export default CoachChatBox;
