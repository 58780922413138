import React from "react";
import { CrossIcon, TickIcon } from "../common/svg";

const FormatText = (text: string | undefined, first_name: string) => {
  const boldRegex = /\*\*(.*?)\*\*/gm;
  const userNameRegx = /{user_name}/g;
  const crossIconRegx = /{cross}/g;
  const tickIconRegx = /{tick}/g;
  const parts = text?.replace(userNameRegx, first_name ? `${first_name}, ` : "")?.split(boldRegex); // Split the text into parts based on the bold pattern
  const parts_with_strong_tag = parts?.map((part, index) => {
    if (index % 2 === 1) {
      // Odd indices contain the bold text, wrap them in <strong> tags
      return <strong key={index}>{part}</strong>;
    } else {
      // Even indices contain the normal text, render them as is
      return part;
    }
  });
  const parts_with_icon = parts_with_strong_tag?.map((part) => {
    if (typeof part === "string" && part.match(crossIconRegx)) {
      return part.split(crossIconRegx).map((str, i) => {
        return i % 2 === 1 ? <CrossIcon /> : str;
      });
    } else if (typeof part === "string" && part.match(tickIconRegx)) {
      return part.split(tickIconRegx).map((str, i) => {
        return i % 2 === 1 ? <TickIcon /> : str;
      });
    }
    return part;
  });
  return parts_with_icon;
};

export default FormatText;
