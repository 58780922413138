import type { ProblemStatement } from "./types";

const CONTEXT = "context";
const CONSTRAINTS = "constraints";
const SUCCESS = "success";
const STACKHOLDER = "stakeholders";
const SCOPE = "scope";
const INSIGHTS = "insights";
export const SMART_PROBLEM = "smart_problem";

export const problemStatementSequence: Array<keyof ProblemStatement> = [CONTEXT, STACKHOLDER, SCOPE, CONSTRAINTS, SUCCESS, INSIGHTS];

export const initialProblemStatement = {
  constraints: {
    text: "",
    exampleText: "While scope defines the space of your solution, constraints focus on what can or cannot happen within that space.",
    state: "locked"
  },
  context: {
    text: "",
    exampleText:
      "Think about the “environment” around the problem you’re facing. Are there complications to consider? This field may encompass current industry trends, availability of finances, or skill gaps on your team.",
    state: "locked"
  },
  insights: {
    text: "",
    exampleText:
      "These are where you will turn for information. Can you leverage available resources from your department, company, or industry?",
    state: "locked"
  },
  scope: {
    text: "",
    exampleText:
      "What will and will not be considered in your solution space? The scope should be broad enough to ensure that you’re considering all relevant solutions, yet narrow enough to ensure that your analysis is manageable.",
    state: "locked"
  },
  smart_problem: {
    text: "",
    exampleText:
      "Start here by writing your problem statement question. Concisely define, in the form of a question what must be resolved (How might we…?). Your question should be SMART: Specific, Measurable, Action-oriented, Relevant, and Time-bound.",
    state: ""
  },
  stakeholders: {
    text: "",
    exampleText:
      "List all the major players critical to your solution. Consider all decision makers who could support—or block—your solution. Also include internal or external parties who might affect how your solution is implemented.",
    state: "locked"
  },
  success: {
    text: "",
    exampleText:
      "What must happen for your solution to be successful? What could happen to make your solution a failure? Criteria should be measurable, so you can judge whether or not your final solution satisfies them.",
    state: "locked"
  }
};

export const statusMap: { [key: string]: any } = {
  "-1": "warning",
  "-2": "error",
  "1": "success"
};
