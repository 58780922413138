import React from "react";
import { InfoModal, INFO_MODAL } from "@mds/mds-reactjs-library";
import styled from "@emotion/styled";
import { ShieldIcon } from "../common/svg";

interface Props {
  handleExample: () => void;
}

const ExampleModal = ({ handleExample }: Props) => {
  return (
    <ModalWrapper>
      <InfoModal
        className='example-modal'
        type={INFO_MODAL}
        header='Problem Statement Worksheet Example'
        scrollable={true}
        onClose={handleExample}
        body={
          <div className='example-wrraper'>
            <div className='left-exampleWrapper'>
              <div className='example-answer'>
                <p>
                  Creating a Problem Statement Worksheet is vital for a team as it ensures clarity and focus, clearly defining the issue and
                  aligning all team members towards a common objective. It enhances strategic problem-solving by facilitating a systematic
                  approach to identifying root causes and developing solutions.
                </p>
              </div>
            </div>
            <div className='right-exampleWrapper'>
              <div className='cardWrapper'>
                <div className='card'>
                  <div className='problem'>
                    <span>Problem question</span>
                    <ShieldIcon />
                  </div>
                  <div className='content'>
                    What opportunities exist for ConsumerFoods Co. to improve market share in the cereal category by 5% by 2025 through
                    enhancements to the North American channel strategy?
                  </div>
                </div>
                <div className='card'>
                  <div className='problem'>
                    <span>Content</span>
                    <ShieldIcon />
                  </div>
                  <div className='content'>
                    ConsumerFoods Co. wants to improve its market share in the cereal category where it has historically underperformed
                    compared to its biggest competitors. Some levers to do so include 1) increasing its penetration with existing customers
                    in the category and 2) capturing new customers. Both can be accomplished through greater sales of existing and new
                    cereal products across the channel
                  </div>
                </div>
                <div className='card'>
                  <div className='problem'>
                    <span>Stakeholders</span>
                    <ShieldIcon />
                  </div>
                  <div className='content'>
                    <ul>
                      <li>Retail partners e.g. grocery, big-box, local mom and pop shops, convenience stores</li>
                      <li>Manufacturing plants</li>
                      <li>Brand management teams </li>
                      <li>Customers </li>
                      <li>Third-party logistics partners </li>
                    </ul>
                  </div>
                </div>
                <div className='card'>
                  <div className='problem'>
                    <span>Constraints</span>
                    <ShieldIcon />
                  </div>
                  <div className='content'>
                    <ul>
                      <li>Increased sales often lag marketing efforts by several months</li>
                      <li>Product R&D can be lengthy and costly</li>
                      <li>Manufacturing plants have capacity restrictions </li>
                      <li>Limited CapEx available to spend on the cereal category </li>
                      <li>No influence over competitor products/responses </li>
                    </ul>
                  </div>
                </div>
                <div className='card'>
                  <div className='problem'>
                    <span>Scope</span>
                    <ShieldIcon />
                  </div>
                  <div className='content'>
                    <ul>
                      <li>Strategizing with existing retail partners</li>
                      <li>Expanding to new retail partners</li>
                      <li>New product launch</li>
                      <li>Marketing campaigns</li>
                      <li>Trade spend optimization and promotions e.g. BOGO</li>
                    </ul>
                  </div>
                </div>
                <div className='card'>
                  <div className='problem'>
                    <span>Success</span>
                    <ShieldIcon />
                  </div>
                  <div className='content'>
                    <ul>
                      <li>Market share in cereal category overall increases by 5%</li>
                      <li>Trade spend remains flat with 0% increase</li>
                      <li>Revenue increases positively; subject to assortment and channel mix as decided through the strategy</li>
                      <li>Retain or improvement current SLA of 98% with retail partners</li>
                    </ul>
                  </div>
                </div>
                <div className='card'>
                  <div className='problem'>
                    <span>Insights</span>
                    <ShieldIcon />
                  </div>
                  <div className='content'>
                    <ul>
                      <li>Industry reports that include channel growth, product growth</li>
                      <li>Competitor 10K reports</li>
                      <li>Existing channel customer data</li>
                      <li>Geographic and demographic trends</li>
                      <li>Sales history and trends including assortment data</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  .mds-modal-frame {
    background: #ccf2ff;
    padding: 0 2.5rem 0 1.5rem;
    max-width: 85%;
  }
  .mds-modal-close-btn {
    color: #333;
    height: 15px;
    width: 15px;
    right: 5px;
  }
  .example-modal .mds-modal-content {
    margin: 0.5rem 0 0.5rem 0;
  }
  .mds-modal-content > div {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .mds-modal-cover {
    position: relative;
    text-align: left;
  }
  > div {
    position: fixed;
    background: rgba(5, 28, 44, 0.5);
  }
  .mds-modal-header {
    color: #051c2c;
    font-family: Bower;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 166%; /* 46.48px */
    letter-spacing: 0.2px;
    flex: 1 1 100%;
  }
  .mds-modal-body {
    flex: 1 1 100%;
  }
  .example-wrraper {
    display: flex;
    gap: 20px;
  }
  .left-exampleWrapper {
    flex: 0.2;
    font-family: "McKinsey Sans";
    font-style: normal;
    text-align: left;
  }
  .right-exampleWrapper {
    flex: 0.79;
  }
  .example-answer {
    font-size: 14px;
    font-weight: 400;
  }
  .cardWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }
  .card {
    padding: 18px;
    gap: 6px;
    border-radius: 6px;
    background: #fff;
    text-align: left;
    width: calC(50% - 42px);
    font-family: "McKinsey Sans";
    font-style: normal;
    box-shadow:
      0px 2px 4px -1px rgba(5, 28, 44, 0.2),
      0px 0px 1px 0px rgba(5, 28, 44, 0.15);
  }
  .card:first-child {
    flex-basis: 100%;
    .content {
      font-weight: 500;
    }
  }
  .card .problem {
    color: #051c2c;
    font-size: 18px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card .approved_btn {
    border: 1px solid #12a195;
    color: #12a195;
    height: 24px;
    outline: none;
    box-shadow: none;
    padding: 6px;
    cursor: default;
  }
  .card .approved_btn:focus {
    box-shadow: none;
  }
  .card .approved_btn:hover {
    background-color: initial;
  }
  .card .content {
    color: #051c2c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .card ol,
  .card ul {
    padding: 0 15px;
  }
`;

export default ExampleModal;
