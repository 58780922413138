import React from "react";

interface LoaderProps {
  background?: string;
  position?: "fixed" | "absolute" | "relative" | "static" | "sticky";
  showBackground?: boolean;
  height?: string;
}

const Loader: React.FC<LoaderProps> = ({ background = "white", position = "fixed", showBackground = false, height = "" }) => {
  const style: React.CSSProperties = {
    position,
    height: height || "100%",
    width: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: background,
    overflow: "auto",
    zIndex: 99
  };

  const whiteBg: React.CSSProperties = {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#ffffff",
    opacity: 0.7
  };

  const loaderWrapperStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "auto",
    fontSize: "1rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  };

  return (
    <div className='loader' style={style}>
      {showBackground && <div style={whiteBg} />}
      <div title='loading' style={loaderWrapperStyle}>
        <div className='loader_animation' />
        <div className='loader_animation' />
        <div className='loader_animation' />
        <div className='loader_animation' />
        <div className='loader_animation' />
      </div>
    </div>
  );
};

export default Loader;
