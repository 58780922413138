import React, { useRef, useEffect, useState } from "react";

interface Props {
  content: string;
  contentClass: string;
}

const DynamicEllipsisComponent = ({ content, contentClass }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [numLines, setNumLines] = useState<number | undefined>();

  useEffect(() => {
    if (containerRef.current) {
      // Measure the height of the container
      const containerHeight = containerRef.current.clientHeight;
      // Calculate the line height (adjust this value based on your design)
      const lineHeight = 26; // Example line height in pixels
      // Calculate the number of lines that can fit within the container
      const calculatedNumLines = Math.floor(containerHeight / lineHeight);
      // Update the state with the calculated number of lines
      setNumLines(calculatedNumLines);
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className={contentClass}
      style={{
        WebkitLineClamp: numLines
      }}
    >
      {content}
    </div>
  );
};

export default DynamicEllipsisComponent;
