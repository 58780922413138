import React, { useState, useEffect } from "react";
import { LockedIcon, ShieldIcon } from "../common/svg";
import { Card, VERTICAL_LAYOUT, ELEVATED, ARTICLE_CARD, Tooltip } from "@mds/mds-reactjs-library";
import { TextArea } from "@mds/mds-reactjs-library";
import DynamicEllipsisComponent from "./DynamicEllipsisComponent";
import type { ProblemStatmentType } from "../modules/Container/types";
import CardAction from "./CardAction";
import spinner from "../assets/spinner.gif";

interface Props {
  heading: string;
  sectionKey: string;
  contentClass?: string;
  data: ProblemStatmentType;
  isChatBotLoading: boolean;
  activeCard: string;
  getQuickLinks: (key: string) => void;
  setActiveCard: (key: string) => void;
  handleSubmit: ({ key, content }: { key: string; content: string }) => void;
  resetQuicklinksData: () => void;
}

const CardLayout = ({
  heading,
  data,
  isChatBotLoading,
  contentClass = "text",
  activeCard,
  setActiveCard,
  sectionKey,
  handleSubmit,
  getQuickLinks,
  resetQuicklinksData
}: Props) => {
  const [inputText, setInputText] = useState<string | null>(data.text);
  const isLocked = data.state === "locked";
  const isSuccess = data.state === "success";
  const isWarned = data.state === "warning";
  const isError = data.state === "error";

  useEffect(() => {
    if (!isChatBotLoading && sectionKey === activeCard && isSuccess) {
      setActiveCard("");
      resetQuicklinksData();
    }
  }, [isChatBotLoading]);

  const getStatusClass = () => {
    if (isSuccess) {
      return "status_success";
    } else if (isWarned) {
      return "status_warned";
    } else if (isError) {
      return "status_error";
    } else {
      return "";
    }
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const onCardClickHandler = (sectionKey: string, isLocked: boolean) => {
    if (!(isLocked || activeCard === sectionKey)) {
      setActiveCard(sectionKey);
      getQuickLinks(sectionKey);
    }
  };

  const EvaluteClickHandler = (e: any) => {
    e.stopPropagation();
    if (inputText) {
      handleSubmit({ key: sectionKey, content: inputText });
    }
  };

  const CancelEdit = (e: any) => {
    e.stopPropagation();
    setActiveCard("");
    setInputText(data.text);
    resetQuicklinksData();
  };

  const renderIcon = (sectionKey: string) => {
    if (activeCard === sectionKey) {
      if (isChatBotLoading) {
        return <img width={30} height={30} src={spinner} alt='spinner' />;
      } else if (isWarned) {
        return <CardAction disabled={!inputText?.trim()} evaluate={EvaluteClickHandler} cancel={CancelEdit} />;
      } else if (isError) {
        return <CardAction disabled={!inputText?.trim()} evaluate={EvaluteClickHandler} cancel={CancelEdit} />;
      }
      return <CardAction disabled={!inputText?.trim()} evaluate={EvaluteClickHandler} cancel={CancelEdit} />;
    }
    if (isLocked) {
      return (
        <Tooltip dark={false} content='Locked until problem question is complete'>
          <span>
            <LockedIcon />
          </span>
        </Tooltip>
      );
    } else if (isSuccess) {
      return (
        <Tooltip dark={false} content='This field is complete'>
          <span>
            <ShieldIcon />
          </span>
        </Tooltip>
      );
    }
    return <></>;
  };
  return (
    <Card
      layout={VERTICAL_LAYOUT}
      variation={ELEVATED}
      type={ARTICLE_CARD}
      width='100%'
      className={`card-container ${activeCard === sectionKey ? "active" : ""} ${getStatusClass()}  ${isLocked ? "disabled" : ""}`}
    >
      <div className='card-wrapper' onClick={() => onCardClickHandler(sectionKey, isLocked)}>
        <div className='card-heading-wrapper'>
          <div className='heading'>{heading}</div>
          {renderIcon(sectionKey)}
        </div>
        {activeCard === sectionKey ? (
          <TextArea size='sm' defaultValue={inputText || ""} onChange={handleTextAreaChange} />
        ) : (
          <DynamicEllipsisComponent
            content={inputText || data.exampleText}
            contentClass={`${contentClass} ${inputText ? "" : "defaultText"}`}
          />
        )}
      </div>
    </Card>
  );
};

export default CardLayout;
