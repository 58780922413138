import React from "react";
import { Button, SECONDARY_BUTTON, SIZE_SMALL } from "@mds/mds-reactjs-library";

interface Props {
  evaluate: (e: any, key: string) => void;
  cancel: (e: any, key: string) => void;
  disabled: boolean;
}

const CardAction = ({ disabled, evaluate, cancel }: Props) => {
  const key = "";
  return (
    <div className='card-action-btn'>
      <Button size={SIZE_SMALL} disabled={disabled} appearance={SECONDARY_BUTTON} onClick={(e) => evaluate(e, key)}>
        Save & Evaluate
      </Button>
      <Button size={SIZE_SMALL} appearance={SECONDARY_BUTTON} onClick={(e) => cancel(e, key)}>
        Cancel
      </Button>
    </div>
  );
};

export default CardAction;
