import React from "react";
import { Typography, Button, PRIMARY_BUTTON } from "@mds/mds-reactjs-library";
import { UnauthenticatedLogo } from "../common/svg";
import "./unauthorized.scss";

type Props = { source: string };

const UnAuthorized: React.FC<Props> = ({ source }) => {
  const redirecttoDocebo = () => {
    window.location.href = "https://academy.mckinseyaccelerate.com/learn/";
  };
  return (
    <div className='unauthenticatedPage' data-testid='unauthenticated'>
      <div className='unauth-header'>
        <div className='header'>
          <img
            src='https://cdn.mckinsey.id/l/logout/mck-logo.png'
            alt='McKID Logo'
            className='Header__Img-sc-1dzz20k-1 gorTDf header__logo'
          />
        </div>
      </div>
      <div className='action_image'>
        <UnauthenticatedLogo />
      </div>
      <div style={{ display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column", height: "100%", flex: "0.9" }}>
        <Typography className='heading-1'>{"Session Timeout"}</Typography>
        <br />
        <Typography className='unauth-text'>The Session has timed out. To access your work,</Typography>
        <Typography className='unauth-text'>
          {source === "docebo" ? "relaunch from your learning dashboard." : "refresh the tab or relaunch from your learning dashboard."}
        </Typography>
      </div>
      {source === "docebo" && (
        <Button
          className='return_btn'
          appearance={PRIMARY_BUTTON}
          onClick={() => {
            redirecttoDocebo();
          }}
        >
          {" "}
          Return to learning dashboard
        </Button>
      )}
    </div>
  );
};

export default UnAuthorized;
