import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface StateType {
  access_token: string;
  user_id: string;
  first_name: string;
  last_name: string;
  lp_id: string;
  source: string;
}

interface AccessToken {
  access_token: string;
}

export const INTITIAL_STATE: StateType = {
  access_token: "",
  user_id: "",
  first_name: "",
  last_name: "",
  lp_id: "",
  source: ""
};
const loginInfoSlice = createSlice({
  name: "loginInfo",
  initialState: INTITIAL_STATE,
  reducers: {
    updateState(state, action: PayloadAction<StateType>) {
      state.access_token = action.payload.access_token;
      state.source = action.payload.source;
      state.user_id = action.payload.user_id;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.lp_id = action.payload.lp_id;
    },
    updateToken(state, action: PayloadAction<AccessToken>) {
      state.access_token = action.payload.access_token;
    }
  }
});
export const { updateState, updateToken } = loginInfoSlice.actions;
export default loginInfoSlice.reducer;
