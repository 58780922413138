import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
interface StateType {
  editMode: boolean;
  saveClicked: boolean;
}

const initialState: StateType = {
  editMode: false,
  saveClicked: false
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setEdit(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
    },
    setSaveClick(state, action: PayloadAction<boolean>) {
      state.saveClicked = action.payload;
    }
  }
});

export const { setEdit, setSaveClick } = commonSlice.actions;
export default commonSlice.reducer;
