import { CONFIG } from "../../utils/env";
import React, { useEffect, useState } from "react";
import { parseJwt } from "../../utils/function";
import { useDispatch } from "react-redux";
import moment from "moment";
import Loader from "../Loader";
import axios from "axios";
import AuthStore from "../../common/AuthStore";
import Unauthenticated from "../Unauthenticated";
import { updateState } from "../../store/login";
import { MidAuthnContext } from "@mid/sdk";

interface MidProps {
  refresh(): unknown;
  accessToken: () => string | null;
  isAuthed: () => Promise<boolean>;
  login: () => Promise<MidAuthnContext>;
}

interface LoginComponentProps {
  mid: MidProps;
  children: React.ReactNode;
}

interface LoginState {
  isAuthed: boolean;
  isLoading: boolean;
  initialLoading: boolean;
}

const LoginComponent: React.FC<LoginComponentProps> = ({ mid, children }) => {
  const dispatch = useDispatch();
  const loading = false;
  const accessToken = localStorage.getItem("access_token") || AuthStore.accessToken;

  const [loginState, updateLoginState] = useState<LoginState>({
    isAuthed: false,
    isLoading: true,
    initialLoading: true
  });
  const [isUnAuthed, setUnauthed] = useState(false);

  const { isAuthed, isLoading, initialLoading } = loginState;

  const checkIsAuth = (checkAuth: boolean) => {
    updateLoginState({ isAuthed: checkAuth, isLoading: false, initialLoading: false });
  };

  const checkTokenExpiration = (): boolean => {
    if (!accessToken) return false;
    const infoFromToken = parseJwt(accessToken);
    const { exp = 0, given_name = "", family_name = "", email = "" } = infoFromToken;
    dispatch(
      updateState({ access_token: accessToken, source: "", user_id: email, lp_id: "", first_name: given_name, last_name: family_name })
    );
    const { exp: userExp = 0 } = infoFromToken || {};
    return exp - moment().unix() > 0 || userExp - moment().unix() > 0;
  };

  useEffect(() => {
    if (accessToken && checkTokenExpiration()) {
      checkIsAuth(true);
    } else if (window.location.pathname.includes("/auth/callback")) {
      mid.isAuthed().then((loginStatus: boolean) => {
        checkIsAuth(loginStatus);
      });
    } else {
      checkIsAuth(false);
      submitHandler();
    }
  }, []);

  const makeUserLogin = () => {
    mid.isAuthed().then((loginStatus: boolean) => {
      if (loginStatus) {
        checkValidateUser();
      } else {
        checkIsAuth(false);
      }
    });
  };

  const checkValidateUser = async () => {
    const midToken = mid.accessToken();
    if (midToken) {
      const infoFromToken = parseJwt(midToken);
      const { auth_time = 0, given_name = "", family_name = "", email = "" } = infoFromToken;
      dispatch(
        updateState({ access_token: midToken, source: "", user_id: email, lp_id: "", first_name: given_name, last_name: family_name })
      );
      try {
        const response = await axios.request({
          method: "GET",
          url: `${CONFIG.TOKEN_VALIDATION}`,
          headers: {
            Authorization: `Bearer ${midToken}`
          }
        });
        const { status = "" } = response;
        if (status === 200) {
          AuthStore.midToken = midToken;
          AuthStore.auth_time = auth_time;
          AuthStore.accessToken = midToken;
          checkIsAuth(true);
        }
      } catch (error) {
        setUnauthed(true);
      }
    } else {
      checkIsAuth(false);
    }
  };

  const submitHandler = () => {
    updateLoginState({ ...loginState, isLoading: true });
    const access_token: string | null = mid.accessToken();
    const token = access_token || "";
    const infoFromToken = parseJwt(token);
    const { exp = 0, given_name = "", family_name = "", email = "" } = infoFromToken;
    dispatch(updateState({ access_token: token, source: "", user_id: email, lp_id: "", first_name: given_name, last_name: family_name }));
    if (exp - moment().unix() > 0) {
      checkValidateUser();
    } else {
      const pathname = window?.location?.pathname || "";
      if (pathname.includes("/knowledge-centre")) {
        AuthStore.redirectionURL = (`${pathname}${window?.location?.search}` || "").replace("/ohi-genai-dev/academy", "");
      } else {
        AuthStore.redirectionURL = "";
      }
      mid.login().finally(() => {
        makeUserLogin();
      });
    }
  };

  if (isAuthed) {
    return <>{children}</>;
  } else if (isUnAuthed) {
    return <Unauthenticated />;
  }

  if (loading || initialLoading) {
    return <Loader position='fixed' showBackground background='transparent' />;
  }

  return <div data-testid='logincomponent'>{isLoading && <Loader position='fixed' showBackground background='transparent' />}</div>;
};

export default LoginComponent;
