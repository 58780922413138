import React from "react";
import { InfoModal, INFO_MODAL } from "@mds/mds-reactjs-library";
import styled from "@emotion/styled";

interface Props {
  handleAckknowledge: () => void;
}

const LegalModal = ({ handleAckknowledge }: Props) => {
  return (
    <ModalWrapper>
      <InfoModal
        type={INFO_MODAL}
        header='You are about to interact with a virtual coach that provides support and suggestions by using generative AI technology.'
        body={
          <>
            <p>
              Before continuing, please review and accept the Virtual Coach{" "}
              <a
                target='_blank'
                href='https://www.mckinsey.com/capabilities/transformation/how-we-help-clients/wave/wave-artificial-intelligence-capabilities'
                rel='noreferrer'
              >
                Legal Notice
              </a>
              {"."}
            </p>
            <p>
              Please do not include any personal information about yourself or any other individuals. Note that all conversations are
              retained for audit and product development purposes.{" "}
            </p>
          </>
        }
        okButton={{
          label: "Acknowledge to continue",
          action: () => {
            handleAckknowledge();
          }
        }}
      />
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  .mds-modal-frame {
    padding: 0 33px;
  }
  .mds-modal-cover {
    position: relative;
    text-align: left;
  }
  > div {
    position: fixed;
    background: linear-gradient(115deg, #2251ff 6.07%, #051d2c 98.23%);
    height: calC(100% - 32px);
  }
  .mds-modal-header {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .mds-modal-close-btn {
    display: none;
  }
  .mds-modal-body p {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: "McKinsey Sans";
  }
  .mds-modal-body p a {
    text-decoration: none;
    color: #1f40e6;
  }
`;

export default LegalModal;
