const ENV = process.env.REACT_APP_ENVIRONMENT as string;
const SERVICE_ACCOUNT_CLIENT_ID = process.env.REACT_APP_SERVICE_CLIENT_ID;
const SERVICE_ACCOUNT_CLIENT_SECRET = process.env.REACT_APP_SERVICE_CLIENT_SECRET;

let API_BASE_PATH = "https://popgenai-dev.ohisurvey.com/";
let REACT_APP_REDIRECT_BASE_URL = "https://pop-genai-dev.mckinseyacademy.com/coach";
let REACT_APP_OIDC_CONFIG_URL = "https://auth.int.mckinsey.id/auth/realms/r/.well-known/openid-configuration";
let REACT_APP_CLIENT_ID = "84573eb7-d8de-4d1c-9604-bacd5f81df02";
let REACT_TOKEN_AUTHENTICATION = "https://auth.int.mckinsey.id/auth/realms/r/protocol/openid-connect/userinfo";
let REACT_GET_TOKEN_URL = "https://auth.int.mckinsey.id/auth/realms/r/protocol/openid-connect/token";

if (ENV === "local") {
  REACT_APP_REDIRECT_BASE_URL = "http://localhost:5000/coach";
}

if (ENV === "stg") {
  REACT_APP_REDIRECT_BASE_URL = "https://pop-genai-stg.mckinseyacademy.com/coach";
  API_BASE_PATH = "https://popgenai-stg.ohisurvey.com/";
}

if (ENV === "prod") {
  REACT_APP_REDIRECT_BASE_URL = "https://pop-genai.mckinseyacademy.com/coach/psw";
  API_BASE_PATH = "https://popgenai.ohisurvey.com/";
  REACT_APP_CLIENT_ID = "be82416c-2ec0-40fc-b141-ad9540c7dc41";
  REACT_APP_OIDC_CONFIG_URL = "https://auth.mckinsey.id/auth/realms/r/.well-known/openid-configuration";
  REACT_TOKEN_AUTHENTICATION = "https://auth.mckinsey.id/auth/realms/r/protocol/openid-connect/userinfo";
  REACT_GET_TOKEN_URL = "https://auth.mckinsey.id/auth/realms/r/protocol/openid-connect/token";
}

export const CONFIG = {
  redirectBaseUrl: REACT_APP_REDIRECT_BASE_URL,
  oidcConfigUrl: REACT_APP_OIDC_CONFIG_URL,
  clientId: REACT_APP_CLIENT_ID,
  BASE_PATH_APP: API_BASE_PATH,
  TOKEN_VALIDATION: REACT_TOKEN_AUTHENTICATION,
  SERVICE_ACCOUNT_CLIENT_ID,
  SERVICE_ACCOUNT_CLIENT_SECRET,
  TOKEN_URL: REACT_GET_TOKEN_URL
};

export const PATHS = {
  ACADEMY: "/coach/psw",
  AUTH_CALL: "/coach/psw/auth/callback",
  HEALTH: "/coach/psw/health"
};
