import React, { useState, useRef } from "react";
import { TextArea, Icon } from "@mds/mds-reactjs-library";
import { ReactComponent as SendIcon } from "../assets/sendIcon.svg";
import Outline24IRemove from "@mds/mds-icons/icons/svg/outline-16-i-remove.svg";

interface Props {
  onSendClick: (_text: string) => void;
  onCloseClick: () => void;
}

const Feedback = ({ onSendClick, onCloseClick }: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [feedbackText, setFeedbackText] = useState<string>("");

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedbackText(event.target.value);
  };

  const handleSendClick = () => {
    if (textareaRef.current) {
      textareaRef.current.value = "";
    }
    onSendClick(feedbackText);
  };

  return (
    <div className='feedback'>
      <div className='close'>
        <Icon src={Outline24IRemove} name='close' onClick={onCloseClick} />
      </div>
      <div className='heading'>Please provide additional feedback</div>
      <div className='text'>
        What was the issue with the Coach?
        <br />
        How could it be improved?
      </div>
      <div className='textareaWrapper'>
        <TextArea ref={textareaRef} placeholder='Type here...' defaultValue={feedbackText} onChange={handleOnChange} />
        <div className='send-icon'>
          <SendIcon onClick={handleSendClick} />
        </div>
      </div>
    </div>
  );
};

export default Feedback;
