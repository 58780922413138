import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";

interface Props {
  content: string | null;
}

const UserChatBox = ({ content }: Props) => {
  const loginData = useSelector((state: RootState) => state.login);
  const { first_name = "A" } = loginData;
  return (
    <>
      {content ? (
        <div className='user-chatbox'>
          <div className='name'>
            <span className='user-avatar'>{first_name.substring(0, 1).toUpperCase()}</span> You
          </div>
          <div className='text'>{content}</div>
        </div>
      ) : null}
    </>
  );
};

export default UserChatBox;
