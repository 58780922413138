class SessionStore {
  private _data!: Storage;
  private static instance: SessionStore;

  constructor() {
    if (!SessionStore.instance) {
      this._data = sessionStorage;
      SessionStore.instance = this;
    }
    return SessionStore.instance;
  }

  set accessToken(val: string | null) {
    if (val) {
      this._data.setItem("access_token", val);
    } else {
      this._data.removeItem("access_token");
    }
  }

  get accessToken(): string | null {
    return this._data.getItem("access_token");
  }

  set source(val: string | null) {
    if (val) {
      this._data.setItem("source", val);
    } else {
      this._data.removeItem("source");
    }
  }

  get source(): string | null {
    return this._data.getItem("source");
  }

  set userId(val: string | null) {
    if (val) {
      this._data.setItem("user_id", val);
    } else {
      this._data.removeItem("user_id");
    }
  }

  get userId(): string | null {
    return this._data.getItem("user_id");
  }

  set firstName(val: string | null) {
    if (val) {
      this._data.setItem("first_name", val);
    } else {
      this._data.removeItem("first_name");
    }
  }

  get firstName(): string | null {
    return this._data.getItem("first_name");
  }

  set lastName(val: string | null) {
    if (val) {
      this._data.setItem("last_name", val);
    } else {
      this._data.removeItem("last_name");
    }
  }

  get lastName(): string | null {
    return this._data.getItem("last_name");
  }

  set lpId(val: string | null) {
    if (val) {
      this._data.setItem("lp_id", val);
    } else {
      this._data.removeItem("lp_id");
    }
  }

  get lpId(): string | null {
    return this._data.getItem("lp_id");
  }
}

const instance = new SessionStore();
Object.freeze(instance);

export default instance;
