/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { ChatHistory, Message, ChatbotPayload, FetchChatHistoryPayload, FeedbackPayload } from "./types";
import { LIMIT } from "./constants";

interface StateType {
  loading: boolean;
  chatbotLoading: boolean;
  chatbotLoadingText: string;
  moreLoading: boolean;
  error: string | null;
  chatHistory: ChatHistory;
  dataOffset: number;
  draftMode?: "off" | "on";
  currentSection?: string | null;
  feebackLoading: boolean;
}

const initialState: StateType = {
  loading: false,
  chatbotLoading: false,
  chatbotLoadingText: "",
  moreLoading: false,
  error: null,
  chatHistory: [],
  draftMode: "off",
  currentSection: null,
  dataOffset: 0,
  feebackLoading: false
};

const chatBotSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    fetchChatbotHistory(state, _action: PayloadAction<FetchChatHistoryPayload>) {
      state.loading = true;
      state.error = null;
    },
    fetchChatbotHistorySuccess(state, action: PayloadAction<ChatHistory>) {
      state.loading = false;
      state.chatbotLoading = false;
      state.moreLoading = false;
      state.error = null;
      const chatHistoryData = [...action.payload, ...state.chatHistory];
      const filterChatHistory = chatHistoryData.filter(
        (value, index, self) => index === self.findIndex((t) => t.message === value.message && t.message_id === value.message_id)
      );
      state.chatHistory = filterChatHistory;
      state.dataOffset += LIMIT;
    },
    fetchChatbotHistoryFailure(state, action) {
      state.loading = false;
      state.moreLoading = false;
      state.chatbotLoading = false;
      state.error = action.payload || "Unable to load conversation";
    },
    fetchMoreHistory(state, _action: PayloadAction<FetchChatHistoryPayload>) {
      state.moreLoading = true;
      state.error = null;
    },
    setChatbotHistory(state, action: PayloadAction<Message>) {
      state.chatbotLoading = false;
      state.chatHistory.push(action.payload);
      state.draftMode = action.payload.draft_mode;
      state.currentSection = action.payload.current_section;
    },
    fetchChatbot(state, _action: PayloadAction<ChatbotPayload>) {
      state.chatbotLoading = true;
      state.error = null;
      if (_action.payload.mode == "chat") {
        state.chatbotLoadingText = "Your coach is working on a response";
      } else {
        state.chatbotLoadingText = "draftmode";
      }
    },
    sendFeedback(state, _action: PayloadAction<FeedbackPayload>) {
      state.feebackLoading = true;
      state.error = null;
    },
    sendFeedbackSuccess(state) {
      state.feebackLoading = false;
      state.error = null;
    },
    sendFeedbackFailure(state) {
      state.feebackLoading = false;
      state.error = null;
    },
    resetChatHistory(state, _action: PayloadAction<ChatbotPayload>) {
      state.chatHistory = [];
    }
  }
});

export const {
  fetchChatbotHistory,
  fetchChatbotHistorySuccess,
  fetchChatbotHistoryFailure,
  setChatbotHistory,
  fetchChatbot,
  fetchMoreHistory,
  sendFeedback,
  sendFeedbackSuccess,
  sendFeedbackFailure,
  resetChatHistory
} = chatBotSlice.actions;

export default chatBotSlice.reducer;
