/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  ProblemStatement,
  savePayload,
  resetPayload,
  problemDataPayload,
  ProblemStatementResponse,
  ProblemStatmentState,
  problemDataStatePayload,
  ProblemStatmentType,
  ProblemStatmentText1,
  ProblemStatementResponseObj,
  QuickLinkResponse,
  ExportResponse
} from "./types";
import { initialProblemStatement, statusMap } from "./constants";

interface StateType {
  loading: boolean;
  error: string | null;
  problemStatementData: ProblemStatement;
  quickLinkData: string[][];
  linksLoading: boolean;
  exportlink: string;
  current_section: string;
  exportLoading: boolean;
}

const initialState: StateType = {
  loading: false,
  error: null,
  problemStatementData: initialProblemStatement,
  quickLinkData: [],
  linksLoading: false,
  exportlink: "",
  current_section: "",
  exportLoading: false
};

type ProblemStatementKeys = keyof ProblemStatement;

function updateProblemStatement(
  problemStatement: ProblemStatement,
  key: ProblemStatementKeys,
  newValue: ProblemStatmentText1 | ProblemStatmentState
): void {
  problemStatement[key] = { ...problemStatement[key], ...newValue };
}

function isProblemStatementKey(key: string): key is keyof ProblemStatement {
  return ["constraints", "context", "insights", "scope", "smart_problem", "stakeholders", "success"].includes(key);
}

const containerSlice = createSlice({
  name: "container",
  initialState,
  reducers: {
    fetchProblemData(state, _action: PayloadAction<problemDataPayload | null>) {
      state.loading = true;
      state.error = null;
    },
    fetchProblemDataSuccess(state, action: PayloadAction<ProblemStatementResponse>) {
      state.loading = false;
      state.error = null;
      const payloaddata: ProblemStatementResponse = action.payload;
      let updateState = false;
      Object.entries(payloaddata).map((item: [string, ProblemStatementResponseObj]) => {
        const [key, value] = item;
        const problemText = value.text;
        const problemStatus = value.flag_status;
        const problemStatementDraft: ProblemStatement = state.problemStatementData;
        if (key && problemText && isProblemStatementKey(key)) {
          const newValue: ProblemStatmentText1 = {
            text: problemText,
            ...(problemStatus !== null && { state: statusMap[problemStatus] })
          };
          updateProblemStatement(problemStatementDraft, key, newValue);
        }
        if (key === "smart_problem" && problemText && problemStatus === "1") {
          updateState = true;
        }
      });
      if (updateState) {
        Object.entries(payloaddata).map((item: [string, ProblemStatementResponseObj]) => {
          const [key, value] = item;
          const problemStatus = value.flag_status;
          const problemStatementDraft: ProblemStatement = state.problemStatementData;
          const newValue: ProblemStatmentState = {
            state: problemStatus ? statusMap[problemStatus] : ""
          };
          if (isProblemStatementKey(key)) {
            updateProblemStatement(problemStatementDraft, key, newValue);
          }
        });
      }
    },
    fetchProblemDataFailure(state, action) {
      state.loading = false;
      state.error = action.payload || "Unable to load Problem Statement";
    },
    updateProblemData(state, _action: PayloadAction<savePayload>) {
      state.error = null;
      state.loading = true;
    },
    updateProblemDataState(state, _action: PayloadAction<problemDataStatePayload>) {
      const status = _action.payload.problem_statement_status.toString();
      const problemStatementDraft: ProblemStatement = state.problemStatementData;
      if (_action.payload.current_section === "smart_problem" && problemStatementDraft["smart_problem"].text !== "") {
        fetchProblemData({ userId: _action.payload.userId, lp_id: _action.payload.lp_id, access_token: _action.payload.access_token });
      }
      Object.entries(problemStatementDraft).map((item: [string, ProblemStatmentType]) => {
        const [key, value] = item;
        if (key === _action.payload.current_section) {
          const newValue: ProblemStatmentText1 = {
            text: _action.payload.user_selection,
            state: statusMap[status]
          };
          if (isProblemStatementKey(key)) {
            updateProblemStatement(problemStatementDraft, key, newValue);
          }
        }
        if (_action.payload.current_section === "smart_problem" && status === "1" && value.state === "locked") {
          const newValue: ProblemStatmentState = {
            state: ""
          };
          if (isProblemStatementKey(key)) {
            updateProblemStatement(problemStatementDraft, key, newValue);
          }
        }
      });
    },
    updateProblemDataSuccess(state) {
      state.error = null;
      state.loading = false;
    },
    updateProblemDataFailure(state) {
      state.loading = false;
      state.error = null;
    },
    triggerReset(state, _action: PayloadAction<resetPayload>) {
      state.loading = true;
      state.error = null;
    },
    triggerResetSuccess(state, _action: PayloadAction<any>) {
      state.loading = false;
      state.problemStatementData = initialProblemStatement;
      state.quickLinkData = [];
      state.current_section = "";
    },
    triggerResetFailure(state, _action: PayloadAction<any>) {
      state.loading = false;
      state.error = _action.payload || null;
    },
    triggerExportProblemData(state, _action: PayloadAction<resetPayload>) {
      state.exportLoading = true;
      state.error = null;
    },
    triggerExportProblemSuccess(state, _action: PayloadAction<ExportResponse>) {
      state.exportLoading = false;
      state.exportlink = _action.payload.link;
    },
    triggerExportProblemFailure(state) {
      state.exportLoading = false;
    },
    triggerGetQuickLinks(state, _action: PayloadAction<any>) {
      state.linksLoading = true;
      state.quickLinkData = [];
      state.error = null;
      state.current_section = "";
    },
    triggerGetQuickLinksSuccess(state, action: PayloadAction<QuickLinkResponse>) {
      state.linksLoading = false;
      state.quickLinkData = action.payload.user_options;
      state.current_section = action.payload.user_section;
    },
    triggerGetQuickLinksFailure(state) {
      state.linksLoading = false;
      state.error = null;
      state.quickLinkData = [];
      state.current_section = "";
    },
    resetExportLink(state) {
      state.exportlink = "";
    },
    resetQuickLinks(state) {
      state.quickLinkData = [];
      state.current_section = "";
    }
  }
});

export const {
  fetchProblemData,
  fetchProblemDataSuccess,
  fetchProblemDataFailure,
  updateProblemData,
  updateProblemDataSuccess,
  updateProblemDataFailure,
  triggerReset,
  triggerResetSuccess,
  triggerResetFailure,
  triggerExportProblemData,
  triggerExportProblemSuccess,
  triggerExportProblemFailure,
  updateProblemDataState,
  triggerGetQuickLinks,
  triggerGetQuickLinksSuccess,
  triggerGetQuickLinksFailure,
  resetExportLink,
  resetQuickLinks
} = containerSlice.actions;
export default containerSlice.reducer;
