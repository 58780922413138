import { call, put, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchChatbotHistory,
  fetchChatbotHistorySuccess,
  fetchChatbotHistoryFailure,
  setChatbotHistory,
  fetchChatbot,
  fetchMoreHistory,
  sendFeedback,
  sendFeedbackSuccess,
  sendFeedbackFailure
} from "./slice";
import { updateProblemDataState, fetchProblemData } from "../Container/slice";
import callApi from "../../utils/api";
import * as constants from "./constants";
import { POST, GET } from "../../utils/constants";
import type { ChatbotPayload, FetchChatHistoryPayload, FeedbackPayload } from "./types";

export const getProblemStatementData = (state: any) => state.container.problemStatementData;

function* fetchChatbotHistorySaga(action: PayloadAction<FetchChatHistoryPayload>): Generator<any, void, any> {
  try {
    const data = yield call(
      callApi,
      `api/academy-ai/get_chat_history?limit=${constants.LIMIT}&offset=${action.payload.offset}&learning_plan_id=${action.payload.lp_id}&docebo_user_id=${action.payload.userId}`,
      GET,
      action.payload.access_token
    );
    const reversedData = data.chat_history.reverse();
    yield put(fetchChatbotHistorySuccess(reversedData));
  } catch (error: any) {
    yield put(fetchChatbotHistoryFailure(error.message));
  }
}

function* fetchMoreHistorySaga(action: PayloadAction<FetchChatHistoryPayload>): Generator<any, void, any> {
  yield fetchChatbotHistorySaga(action);
}

function* fetchChatBotSaga(action: PayloadAction<ChatbotPayload>): Generator<any, void, any> {
  const { userId, user_selection, evaluate, lp_id, access_token, mode, current_section } = action.payload;
  const payload = {
    user_id: userId,
    learning_plan_id: lp_id,
    session_id: "1",
    user_selection: user_selection,
    evaluate: evaluate,
    current_section,
    mode
  };
  try {
    const data = yield call(callApi, `api/academy-ai/aai_bot_v2`, POST, access_token, payload);
    const problem_statement_status = data.flag_status;
    yield put(setChatbotHistory({ ...data, user_type: constants.BOT }));
    const problemStatementDraft = yield select(getProblemStatementData);
    if (mode === "draft" && current_section === "smart_problem" && problemStatementDraft["smart_problem"].text !== "") {
      yield put(fetchProblemData({ userId, lp_id, access_token }));
    } else if (mode === "draft" && user_selection) {
      yield put(updateProblemDataState({ current_section, user_selection, problem_statement_status, userId, lp_id, access_token }));
    }
  } catch (error: any) {
    yield put(fetchChatbotHistoryFailure(error.message));
  }
}

function* sendFeedbackSaga(action: PayloadAction<FeedbackPayload>): Generator<any, void, any> {
  const payload = { ...action.payload, learning_plan_id: action.payload.lp_id, session_id: "1" };
  try {
    yield call(callApi, `api/academy-ai/feedback`, POST, action.payload.access_token, payload);
    yield put(sendFeedbackSuccess());
  } catch (error: any) {
    yield put(sendFeedbackFailure(error.message));
  }
}

const chatbotSaga = [
  takeLatest(fetchChatbotHistory.type, fetchChatbotHistorySaga),
  takeLatest(fetchChatbot.type, fetchChatBotSaga),
  takeLatest(fetchMoreHistory.type, fetchMoreHistorySaga),
  takeLatest(sendFeedback.type, sendFeedbackSaga)
];

export default chatbotSaga;
