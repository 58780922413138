import React from "react";

const AppFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer>© {currentYear} McKinsey & Company</footer>
    </>
  );
};

export default AppFooter;
