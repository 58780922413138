import { GET } from "./constants";
import { CONFIG } from "./env";

async function callApi(url: string, method: string = GET, access_token = "", payload?: object) {
  try {
    const options: RequestInit = {
      method: method,
      headers: {
        "Content-Type": "application/json", // Assuming JSON payload
        ...(access_token && { Authorization: `Bearer ${access_token}` })
      },
      body: JSON.stringify(payload)
    };

    const response = await fetch(`${CONFIG.BASE_PATH_APP}${url}`, options);
    if (response.status === 401) {
      throw new Error("Signature Expired");
    } else if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error: any) {
    throw new Error(error.message || "Failed to fetch data");
  }
}

export default callApi;
