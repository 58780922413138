// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fade {
  from {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
.blink {
  animation: fade 4000ms infinite ease-in;
  -webkit-animation: fade 4000ms infinite ease-in;
}

.fade-in {
  transition: opacity 0.5s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease;
}`, "",{"version":3,"sources":["webpack://./src/modules/Chatbot/styles.scss"],"names":[],"mappings":"AAAA;EACI;IAAO,UAAA;EAET;EADE;IAAM,UAAA;EAIR;EAHE;IAAM,YAAA;EAMR;EALE;IAAK,UAAA;EAQP;AACF;AACA;EACE,uCAAA;EACA,+CAAA;AAeF;;AAZA;EACI,6BAAA;AAeJ;;AAZA;EACI,UAAA;EACA,6BAAA;AAeJ","sourcesContent":["@keyframes fade {\n    from { opacity: 1.0; }\n    80% { opacity: 1.0; }\n    90% { opacity: 0.3; }\n    to { opacity: 1.0; }\n}                                                                                                                                                                                                                                  \n\n@-webkit-keyframes fade {\n    from { opacity: 1.0; }\n    80% { opacity: 1.0; }\n    90% { opacity: 0.3; }\n    to { opacity: 1.0; }\n}\n\n.blink {\n  animation:fade 4000ms infinite ease-in;\n  -webkit-animation:fade 4000ms infinite ease-in;\n}\n\n.fade-in {\n    transition: opacity 0.5s ease;\n}\n\n.fade-out {\n    opacity: 0;\n    transition: opacity 0.5s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
