import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import {
  SIZE_LARGE,
  SIZE_SMALL,
  CircularProgress,
  TextArea,
  pushToast,
  removeToast,
  ToastFactory,
  Button,
  SIZE_MEDIUM,
  SECONDARY_BUTTON
} from "@mds/mds-reactjs-library";
import { ReactComponent as SendIcon } from "../../assets/sendIcon.svg";
import CoachChatBox from "../../components/CoachChatBox";
import UserChatBox from "../../components/UserChatBox";
import { fetchChatbot, fetchChatbotHistory, setChatbotHistory, fetchMoreHistory, sendFeedback } from "./slice";

import * as constants from "./constants";
import { DislikePayload, FadeEffect } from "./types";
import { QuickLinkIcon } from "../../common/svg";
import spinner from "../../assets/spinner.gif";
import "./styles.scss";

const Chatbot = () => {
  const dispatch = useDispatch();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fadedText: string[] = ["Analyzing response...", "Compiling feedback...", "Generating response..."];

  const [userInput, setUserInput] = useState<string>("");
  const [prevHeight, setPrevHeight] = useState<number>(0);
  const [ToastId, setToastId] = React.useState<number>(1);
  const [fadedElement, setFadedElement] = React.useState<boolean>(false);
  const [fadeProp, setFadeProp] = useState<FadeEffect>({ fade: "fade-in", index: 0 });

  const chatHistory = useSelector((state: RootState) => state.chatbot.chatHistory);
  const isLoading = useSelector((state: RootState) => state.chatbot.loading);
  const isMoreLoading = useSelector((state: RootState) => state.chatbot.moreLoading);
  const offset = useSelector((state: RootState) => state.chatbot.dataOffset);
  const isChatbotLoading = useSelector((state: RootState) => state.chatbot.chatbotLoading);
  const chatbotLoadingText = useSelector((state: RootState) => state.chatbot.chatbotLoadingText);
  const draftMode = useSelector((state: RootState) => state.chatbot.draftMode);
  const currentSection = useSelector((state: RootState) => state.container.current_section);
  const userOptionData = useSelector((state: RootState) => state.container.quickLinkData);
  const error = useSelector((state: RootState) => state.container.error);
  const lp_id = useSelector((state: RootState) => state.login.lp_id);
  const uid = useSelector((state: RootState) => state.login.user_id);
  const access_token = useSelector((state: RootState) => state.login.access_token);

  const quickLinkIcon = <QuickLinkIcon />;

  useEffect(() => {
    if (uid) {
      dispatch(fetchChatbotHistory({ userId: uid, offset, lp_id: lp_id || `${constants.LEARNING_PLAN_ID}`, access_token }));
    }
  }, [uid]);

  useEffect(() => {
    if (error) {
      pushToast({
        id: ToastId.toString(),
        type: "danger",
        children: error,
        autoRemoveDuration: 3000,
        onCloseClick: () => removeToast("1")
      });
      setToastId(ToastId + 1);
    }
  }, [error]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const newScrollHeight = chatContainerRef.current.scrollHeight;
      const scrollDifference = newScrollHeight - prevHeight;
      const containerHeight = chatContainerRef.current.clientHeight;
      const containerScrollTop = chatContainerRef.current.scrollTop;
      if (containerScrollTop > containerHeight) {
        setFadedElement(true);
      } else {
        setFadedElement(false);
      }
      chatContainerRef.current.scrollBy({ top: scrollDifference, behavior: "smooth" });
    }
  }, [chatHistory, isChatbotLoading]);

  useEffect(() => {
    let timeout: ReturnType<typeof setInterval>;
    if (isChatbotLoading && chatbotLoadingText === "draftmode") {
      timeout = setInterval(() => {
        if (fadeProp.index >= 2) {
          clearInterval(timeout);
        }
        if (fadeProp.fade === "fade-in" && fadeProp.index < 2) {
          setFadeProp({
            fade: "fade-out",
            index: fadeProp.index
          });
        }
      }, 3000);
    } else {
      setFadeProp({
        fade: "fade-in",
        index: 0
      });
    }
    return () => clearInterval(timeout);
  }, [fadeProp, isChatbotLoading]);

  const onUserInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };

  const handleSendClick = () => {
    if (userInput.trim()) {
      const chatBotMessage = {
        user_type: constants.USER,
        message: userInput,
        draft_mode: draftMode,
        current_section: currentSection || "smart_problem"
      };
      if (textareaRef.current) {
        textareaRef.current.value = "";
      }
      setUserInput("");

      dispatch(setChatbotHistory(chatBotMessage));
      dispatch(
        fetchChatbot({
          userId: uid,
          lp_id: lp_id || `${constants.LEARNING_PLAN_ID}`,
          user_selection: userInput,
          access_token,
          current_section: currentSection || "smart_problem",
          mode: "chat"
        })
      );
    }
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      setPrevHeight(0);
      handleSendClick();
    }
  };

  const handleUserOptionClick = (key: string) => {
    const chatBotMessage = {
      user_type: constants.USER,
      message: key,
      draft_mode: draftMode,
      current_section: currentSection
    };
    dispatch(setChatbotHistory(chatBotMessage));
    dispatch(
      fetchChatbot({
        userId: uid,
        lp_id: lp_id || `${constants.LEARNING_PLAN_ID}`,
        user_selection: key,
        access_token,
        current_section: currentSection,
        mode: "chat"
      })
    );
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop } = chatContainerRef.current;
      if (scrollTop === 0 && !isMoreLoading) {
        setPrevHeight(chatContainerRef.current.scrollHeight);
        dispatch(fetchMoreHistory({ userId: uid, offset, lp_id: lp_id || `${constants.LEARNING_PLAN_ID}`, access_token }));
      }
      if (scrollTop > 100) {
        setFadedElement(true);
      } else {
        setFadedElement(false);
      }
    }
  };

  const handleDislikeClick = ({ feedback, feedback_comment, message_id }: DislikePayload) => {
    const sendFeedbackPayload = {
      docebo_user_id: uid,
      feedback,
      feedback_comment,
      message_id,
      lp_id: lp_id || `${constants.LEARNING_PLAN_ID}`,
      access_token
    };
    dispatch(sendFeedback(sendFeedbackPayload));
  };

  const updateLoadingText = () => {
    if (fadeProp.fade === "fade-out") {
      setFadeProp({
        fade: "fade-in",
        index: fadeProp.index + 1
      });
    }
  };

  return (
    <div className='chatbot-container'>
      {fadedElement && <div className='faded_gradient'></div>}

      {isLoading && (
        <div className='loader'>
          <CircularProgress indeterminate size={SIZE_LARGE} />
        </div>
      )}
      <div className='chat-section' ref={chatContainerRef} onScroll={handleScroll}>
        <ToastFactory />
        {isMoreLoading && (
          <div className='more-loader'>
            <CircularProgress indeterminate size={SIZE_SMALL} />
          </div>
        )}
        {chatHistory &&
          chatHistory.map((chat, index) => {
            return chat.user_type === constants.BOT ? (
              <CoachChatBox content={chat} key={index} onDislikeClick={handleDislikeClick} />
            ) : (
              <UserChatBox key={index} content={chat.message} />
            );
          })}
      </div>
      {isChatbotLoading && (
        <div className='loadingText'>
          <img className='spinner_loader' src={spinner} />{" "}
          {chatbotLoadingText === "draftmode" ? (
            <span className={`${fadeProp.index < 2 ? fadeProp.fade : "blink"}`} onTransitionEnd={() => updateLoadingText()}>
              {fadedText[fadeProp.index] ? fadedText[fadeProp.index] : fadedText[fadedText.length - 1]}
            </span>
          ) : (
            <>
              <span className={"blink"}>{fadedText[fadedText.length - 1]}</span>{" "}
            </>
          )}
        </div>
      )}
      {userOptionData &&
        (userOptionData.length ? (
          <div className='user-optionContainer'>
            {userOptionData.map((option, index) => (
              <Button
                key={index}
                size={SIZE_MEDIUM}
                appearance={SECONDARY_BUTTON}
                onClick={() => {
                  handleUserOptionClick(option[0]);
                }}
                startIcon={quickLinkIcon}
                disabled={isChatbotLoading}
              >
                {option[0]}
              </Button>
            ))}
          </div>
        ) : (
          <></>
        ))}
      <div className='input-text-area'>
        <TextArea
          onChange={onUserInputChange}
          placeholder='Type or select a prompt'
          defaultValue={userInput}
          onKeyDown={handleEnter}
          ref={textareaRef}
          disabled={isChatbotLoading}
        />
        <SendIcon className={`send-icon ${isChatbotLoading ? "disabled" : ""}`} onClick={handleSendClick} />
      </div>
    </div>
  );
};

export default Chatbot;
