import React from "react";
import { InfoModal, INFO_MODAL } from "@mds/mds-reactjs-library";
import styled from "@emotion/styled";
import exploreIcon from "../assets/exploreIcon.svg";

interface Props {
  handleExploreTutorial: () => void;
  handleModalClose: () => void;
}

const ExploreModal = ({ handleExploreTutorial, handleModalClose }: Props) => {
  return (
    <ModalWrapper>
      <InfoModal
        className='explore-modal'
        type={INFO_MODAL}
        header='How this works:'
        onClose={handleModalClose}
        body={
          <>
            <p>
              A well-developed problem statement worksheet helps clearly define the issue at hand, ensuring all stakeholders have a common
              understanding of the problem. This worksheet aids in identifying underlying causes, potential impacts, and guides the
              formulation of more effective solutions. It serves as a reference point throughout the problem-solving process, helping focus
              and alignment with the desired outcomes.
            </p>
          </>
        }
        okButton={{
          label: "Explore Tutorial",
          action: () => {
            handleExploreTutorial();
          }
        }}
      />
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  .mds-modal-frame {
    background: #ccf2ff;
    padding: 0 1.5rem 0 1.5rem;
    max-width: 700px;
  }
  .mds-modal-content > div {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .mds-modal-cover {
    position: relative;
    text-align: left;
  }
  .mds-modal-close-btn {
    color: #333333;
    height: 15px;
    width: 15px;
  }
  > div {
    position: fixed;
    background: rgba(5, 28, 44, 0.5);
  }
  .mds-modal-header {
    color: #051c2c;
    font-family: Bower;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 166%; /* 46.48px */
    letter-spacing: 0.2px;
    flex: 1 1 100%;
  }
  .mds-modal-body {
    flex: 1 1 60%;
    margin-right: 50px;
  }
  .mds-modal-body p {
    text-align: left;
    margin-top: 0;
  }
  .mds-button {
    padding-left: 2rem;
  }
  .mds-button::before {
    content: " ";
    background-image: url(${exploreIcon});
    position: absolute;
    width: 16px;
    height: 16px;
    left: 10px;
    top: calc(50% - 6px);
    z-index: 11111;
  }
`;

export default ExploreModal;
