import React from "react";
import { ThemeProvider } from "@mds/mds-reactjs-library";
import { useSelector } from "react-redux";
import { Mid } from "@mid/sdk";
import Footer from "./modules/Footer";
import Container from "./modules/Container";
import { PATHS } from "./utils/env";
import { Routes, Route } from "react-router-dom";
import CallbackComponent from "./components/CallbackComponent";
import { RootState } from "./store/reducers";
import UnAuthorized from "./components/UnAuthorized";

interface Props {
  mid: Mid;
}

const RoutesComponent = ({ mid }: Props) => {
  const jsonData = {
    message: "health check"
  };

  const error = useSelector((state: RootState) => state.container.error);
  const errorChat = useSelector((state: RootState) => state.chatbot.error);
  const { source } = useSelector((state: RootState) => state.login);

  return (
    <Routes>
      <Route path={PATHS.AUTH_CALL} element={<CallbackComponent mid={mid} />} />
      <Route
        path={PATHS.ACADEMY}
        element={
          <ThemeProvider>
            <div className='App'>
              {error === "Signature Expired" || errorChat === "Signature Expired" ? (
                <UnAuthorized source={source} />
              ) : (
                <>
                  <Container></Container>
                  <Footer></Footer>
                </>
              )}
            </div>
          </ThemeProvider>
        }
      ></Route>

      <Route path={PATHS.HEALTH} element={<pre>({JSON.stringify(jsonData, null, 2)})</pre>} />
    </Routes>
  );
};

export default React.memo(RoutesComponent);
